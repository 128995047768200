import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          mainUrl
        }
      }
    }
  `)

  const mainUrl = data.site.siteMetadata?.mainUrl

  return (
    <header className="w-100 flex justify-between pv3 bb b--primary">
      <Link className="link" to="/">
        BLOG
      </Link>
      <a className="link fw9" href={mainUrl}>
        ESTRUTO
      </a>
    </header>
  )
}

export default Header
